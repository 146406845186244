import React from 'react';
import store from '../../store';
import { getTransactionDetails } from '../../providers/authorizations';
import moment from 'moment';
import { getEmailById } from '../../providers/users';
import { Button } from 'react-bootstrap';
import Overlay from 'react-bootstrap/es/Overlay';
import Popover from 'react-bootstrap/es/Popover';
import NumberFormat from 'react-number-format';
import { merchantsAdmin } from '../../constants';
import connect from 'react-redux/es/connect/connect';
import Tooltip from '../common/Tooltip';

import { filterByKeyValue, formatAmount, getSignedAmount, maskCard } from '../../lib/utils';
import NumberFormatter from '../common/NumberFormatter';
import Loader from '../common/Loader';
import GenericModal from '../common/GenericModal';

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n

const lodash = require("lodash");

const stateMap = (store) => {
    return {
        userReducer: store.userReducer
    };
};

const euro = " €";

export class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            approvalResponseCodes: props.allMaps.approvalResponseCodes,
            cardlinkOneDeclinedResponseCodes: props.allMaps.cardlinkOneDeclinedResponseCodes,
            authorizationId: '',
            authorizationDate: '',
            authorizationTime: '',
            cardNumber: '',
            cardTypeBrand: '',
            authorizationAmount: '',
            response: '',
            responseCode: '',
            merchantId: '',
            terminalId: '',
            merchantAlias: '',
            terminalAlias: '',
            vMerchantId: '',
            vTerminalId: '',
            totalInstalments: '',
            bank: '',
            acquirerBankId: '',
            redemptionAmount: '',
            tillNumber: '',
            cashierNumber: '',
            paymentInfo: '',
            transactionType: '',
            transactionAttribute: '',
            cardholderVerMode: '',
            cardEntryMode: '',
            contactlessFlag: '',
            authorizationCode: '',
            retrievalReferenceNumber: '',
            closeBatch: '',
            settlementDate: '',
            status3D: '',
            infoField1: '',
            infoField2: '',
            infoField3: '',
            dccFlag: '',
            dccAmount: '',
            dccCurrency: '',
            dccRate: '',
            cardlessType: '',
            status: '',
            originalTerminalId: '',
            paymentCode: '',
            walletId: '',
            remainingAmount: '',
            refundSum: '',
            refundedOn: '',
            refundedBy: '',
            tipsAmount: '',
            ecommerceOrderId: '',
            amountToRefund: null,
            showRefundModal: false,
            isCardlinkOne: false,
            merchantFee: '',
            commissionAmountMerchant: '',
            commissionAmountAcquirer: '',
            commissionAmountAcquirerOthers: '',
            paymentReqDate: '',
            paymentRespDate: '',
            paymentRespMessage: ''
        }
        // TODO change the last state properties (after isCardlinkOne) according to real transaction info

        this.getTransactionDetails = this.getTransactionDetails.bind(this);
        this.cardSchemeFormatter = this.cardSchemeFormatter.bind(this);
        this.bankFormatter = this.bankFormatter.bind(this);
        this.cardholderVerModeFormatter = this.cardholderVerModeFormatter.bind(this);
        this.getEmailById = this.getEmailById.bind(this);
        this.toggleRefundWindow = this.toggleRefundWindow.bind(this);
        this.handleFormattedChange = this.handleFormattedChange.bind(this);
        this.authorizationNotOlderThan1Year = this.authorizationNotOlderThan1Year.bind(this);
        this.cardlessTransAndStatusNot1 = this.cardlessTransAndStatusNot1.bind(this);
        this.refundButtonHoverText = this.refundButtonHoverText.bind(this);
        this.defineCardlinkOnePaymentResponse = this.defineCardlinkOnePaymentResponse.bind(this);
    }

    getEmailById(refundedBy) {
        store.dispatch(getEmailById(refundedBy)).then(
            (response) => {
                this.setState({
                    refundedBy: response.value.data.payload
                })
            })
    }

    componentWillMount() {
        this.getTransactionDetails()
    }

    contactlessFlagFormatter(cell) {
        const contactlessFlags = this.props.allMaps.contactlessStatus;
        let contactlessFlag = contactlessFlags.find(item => {
            return item.value === cell;
        });
        if (contactlessFlag !== undefined) return contactlessFlag.label
        else return ''
    }

    cardholderVerModeFormatter(cell) {
        const cardholderVerificationModes = this.props.allMaps.cardholderVerificationMode;
        let currentMode = cardholderVerificationModes.find(item => {
            return item.value === cell;
        });
        if (currentMode !== undefined) return currentMode.label
        else return ''
    }

    cardEntryMode(cell) {
        const cardEntryModes = this.props.allMaps.cardEntryMode;
        let currentMode = cardEntryModes.find(item => {
            return item.value === cell;
        });
        if (currentMode !== undefined) return currentMode.label
        else return ''
    }

    bankFormatter(cell, transactionType) {
        const bankNames = this.props.allMaps.acquirers;
        let rowBankName = transactionType !== 'L' ? bankNames.find(item => {
            return item.value === cell;
        }) : undefined;
        let bankCls = rowBankName !== undefined ? "bg-" + (rowBankName.label.toLowerCase().replace(" ", "_")) : '';
        return (
            transactionType === 'L' ?
                <Translate value={"pages.transactions.table.transactionTypes.asfalistikosGoneas"}/> :
                rowBankName === undefined ?
                    <span>
                        <Translate value={"pages.transactions.table.transactionTypes.unknown"}/>
                    </span> :
                    [<div className={"bank-icon-wrapper" + (
                            cell === '887' ? "__one" :
                                cell === '026' ? "__worldline" :
                                    cell === '014' ? "__nexi" : "")
                        + " transaction__details__icon--no-print"}>
                    <span
                        className={bankCls + (cell === '887' ? "" : " shrinked")}> {''} </span>
                    </div>,
                        <span
                            className="transaction__details--print-lbl"> {lodash.startCase(lodash.toLower(rowBankName.label))} </span>
                    ]
        )
    }

    cardSchemeFormatter(cell) {
        let schemeCls = cell !== null && cell !== "" ? "bg-" + (cell.toLowerCase().replace(" ", "_")) : '';
        return (
            <div className="scheme-icon-wrapper">
                <span className={schemeCls}> {cell !== null && cell !== "" ? '' : '-'} </span>
            </div>
        )
    }

    defineCardlinkOnePaymentResponse(payResp) {
        let payRespLowerCase = payResp.toLowerCase();
        switch (true) {
            case (payRespLowerCase === "acsc" || payRespLowerCase === "accp" || payRespLowerCase === "acsp"):
                return 'pages.transactions.details.cardlinkOneSuccessfulPayment';
            case (payRespLowerCase === "rjct"):
                return "pages.transactions.details.cardlinkOneUnsuccessfulPayment";
            default:
                return "-";
        }
    }

    getTransactionDetails() {
        store.dispatch(getTransactionDetails(this.props.authorizationId, this.props.authorizationDate)).then(
            (response) => {
                const data = response.value.data.payload;
                // replace all but last 4 digits with ****
                let maskedCardNumber = maskCard(data.maskedCardNumber);

                const remainingAmount = data.authorizationAmount !== null && data.refundSum !== null ?
                    parseFloat((data.authorizationAmount - data.refundSum).toFixed(2)) : '-';
                this.setState({
                    authorizationId: data.authorizationId,
                    authorizationDate: data.authorizationDate !== null ? moment(data.authorizationDate + ' ' + data.authorizationTime).format('DD/MM/YYYY HH:mm') : '-',
                    maskedCardNumber: maskedCardNumber,
                    cardTypeBrand: data.cardTypeBrand !== null ? data.cardTypeBrand : '-',
                    authorizationAmount: data.authorizationAmount !== null ? data.authorizationAmount : '-',
                    amountSign: data.amountSign !== null ? data.amountSign : 1,
                    response: data.responseCode ? this.state.approvalResponseCodes.includes(data.responseCode) ? I18n.t('pages.transactions.details.approved') :
                        data.acquirerBankId === '887' && this.state.cardlinkOneDeclinedResponseCodes.includes(data.responseCode) ? I18n.t('pages.transactions.details.declinedCardlinkOne') :
                            I18n.t('pages.transactions.details.declined') : '-',
                    responseCode: data.responseCode,
                    merchantId: data.merchantId !== null ? data.merchantId : '-',
                    terminalId: data.terminalId !== null ? data.terminalId : '-',
                    merchantAlias: filterByKeyValue(this.props.aliasesArr, data.merchantId),
                    terminalAlias: filterByKeyValue(this.props.aliasesArr, data.terminalId),
                    vMerchantId: data.vMerchantId !== null ? data.vMerchantId : '-',
                    vTerminalId: data.vTerminalId !== null ? data.vTerminalId : '-',
                    bank: this.bankFormatter(response.value.data.payload.acquirerBankId, data.transactionType),
                    acquirerBankId: response.value.data.payload.acquirerBankId,
                    totalInstalments: data.numberOfTotalInstalments !== null ? data.numberOfTotalInstalments : '-',
                    redemptionAmount: data.redemptionAmount !== null ? formatAmount(I18n._locale, data.redemptionAmount, 2) + euro : '-',
                    tillNumber: data.tillNumber !== null ? data.tillNumber : '-',
                    cashierNumber: data.cashierNumber !== null ? data.cashierNumber : '-',
                    paymentInfo: data.paymentInfo01 ? data.paymentInfo01.replace(/^0+/, '') : '-',
                    transactionType: data.transactionType !== null ? this.props.transTypeFormatter(data.transactionType, data) : '-',
                    transactionAttribute: data.transactionAttribute !== null ? data.transactionAttribute : '-',
                    cardholderVerMode: data.tlgcmauth ?
                        this.cardholderVerModeFormatter(data.tlgcmauth) : '-',
                    cardEntryMode: data.tlgentry !== null ?
                        this.cardEntryMode(data.tlgentry) : '-',
                    contactlessFlag: data.contactlessStatus !== null ?
                        this.contactlessFlagFormatter(data.contactlessStatus) : '-',
                    authorizationCode: data.authorizationCode !== null ? data.authorizationCode : '-',
                    retrievalReferenceNumber: data.retrievalReferenceNumber !== null ?
                        data.retrievalReferenceNumber.substr(data.retrievalReferenceNumber.length - 6) : '-',
                    closeBatch: data.reconciledDateTime !== null ? moment(data.reconciledDateTime).format('DD/MM/YYYY HH:mm:ss') :
                        data.closedBatchDateTime !== null ? moment(data.closedBatchDateTime).format('DD/MM/YYYY HH:mm:ss') : '-',
                    settlementDate: data.settlementDate !== null ?
                        moment(data.settlementDate).format('DD/MM/YYYY') : '-',
                    status3D: data.status3D !== null ?
                        data.status3D : '-',
                    infoField1: data.usrsfid9r1,
                    infoField2: data.usrsfid9r2 !== null ? data.usrsfid9r2 : '-',
                    infoField3: data.usrsfid9r3 !== null ? data.usrsfid9r3 : '-',
                    dccFlag: data.dccFlag !== null ? data.dccFlag : '-',
                    dccAmount: data.dccAmount !== null ? data.dccAmount : '-',
                    dccCurrency: data.dccCurrency !== null ? data.dccCurrency : '-',
                    dccRate: data.dccRate !== null ? data.dccRate : '-',
                    cardlessType: data.cardless_type,
                    status: data.status,
                    originalTerminalId: data.originalTerminalId !== null ? data.originalTerminalId : '-',
                    paymentCode: data.payment_code !== null ? data.payment_code : '-',
                    walletId: data.wallet_id !== null ? data.wallet_id : '-',
                    refundSum: data.refundSum !== null ? data.refundSum : '-',
                    amountToRefund: remainingAmount !== '-' ? remainingAmount : 0,
                    remainingAmount: remainingAmount,
                    refundedOn: data.voidedOn !== null ? moment(data.voidedOn).format('DD/MM/YYYY') : '-',
                    isVoid: data.isVoid || data.voidedOn !== null,
                    transactionTypeCode: data.transactionType,
                    tipsAmount: data.tipsAmount !== null ? data.tipsAmount : 0,
                    ecommerceOrderId: data.ecommerceOrderId !== null ? data.ecommerceOrderId : '-',
                    isCardlinkOne: data.acquirerBankId === '887',
                    merchantFee: data.merchantFee ? data.merchantFee : 0,
                    commissionAmountMerchant: data.commissionAmountMerchant ? data.commissionAmountMerchant : 0,
                    commissionAmountAcquirer: data.commissionAmountAcquirer ? data.commissionAmountAcquirer : 0,
                    commissionAmountAcquirerOthers: data.commissionAmountAcquirerOthers ? data.commissionAmountAcquirerOthers : 0,
                    paymentReqDate: data.paymentReqDate ? moment(data.paymentReqDate).format('DD/MM/YYYY') : '-',
                    paymentRespDate: data.paymentRespDate ? moment(data.paymentRespDate).format('DD/MM/YYYY') : '-',
                    paymentRespMessage: data.paymentRespMessage ? (this.defineCardlinkOnePaymentResponse(data.paymentRespMessage)) : '-',
                    xlsId: data.xlsId !== null ? data.xlsId : '-',
                    loyaltyProgram: data.loyaltyProgram !== null ? this.props.loyaltyProgramAliasesFormatter(data.loyaltyProgram, data) : '-',
                    wallet: data.wallet !== null ? this.props.walletAliasFormatter(data.wallet, data) : '-'
                })
                if (data.voidedBy !== null) {
                    this.getEmailById(data.voidedBy)
                } else {
                    this.setState({
                        refundedBy: '-'
                    })
                }
            },
            (error) => {
            })
    }

    authorizationNotOlderThan1Year() {
        return moment(this.state.authorizationDate, "DD/MM/YYYY HH:mm").isSameOrAfter(moment().subtract(1, 'year'));
    }

    cardlessTransAndStatusNot1() {
        return this.state.cardlessType && this.state.status !== 1
    }

    toggleRefundWindow(e) {
        this.setState({
            showRefundWindow: !this.state.showRefundWindow
        })
    }

    handleFormattedChange(obj, ev) {
        let floatValue = I18n._locale === "el" ?
            parseFloat(obj.formattedValue.replace(/\./g, "").replace(/,/, '.')) :
            parseFloat(obj.formattedValue.replace(/,/, ''));
        if (floatValue > this.state.remainingAmount) {
            this.setState({
                [ev.target.id]: this.state.remainingAmount
            })
        } else if (floatValue <= 0) {
            this.setState({
                [ev.target.id]: formatAmount(I18n._locale, 0.01, 2)
            })
        } else if (floatValue > 9999999.99) {
            this.setState({
                [ev.target.id]: formatAmount(I18n._locale, 9999999.99, 2)
            })
        } else {
            this.setState({
                [ev.target.id]: obj.formattedValue
            })
        }
    }

    refundButtonHoverText() {
        if (!this.authorizationNotOlderThan1Year()) {
            return <Translate value="pages.transactions.details.transactionOlderThan1Year"/>
        }
    }

    render() {
        let isApproved = this.state.approvalResponseCodes.includes(this.state.responseCode);
        let isImpersonator = this.props.userReducer.impersonator !== null
        let isPurchase = this.state.transactionTypeCode === "10"
        let isBillingPayment = this.state.transactionTypeCode === "U" || this.state.transactionTypeCode === "UG" || this.state.transactionTypeCode === "UUG"
        let hasPermissionToRefund = this.props.userReducer.groups && this.props.userReducer.groups.indexOf(merchantsAdmin) >= 0
        let hasRemainingAmount = this.state.remainingAmount && this.state.remainingAmount > 0
        let isTrxClosed = this.state.closeBatch !== '-'
        let isTrxSettled = this.state.settlementDate !== '-'
        let canRefund = hasPermissionToRefund && ((isPurchase && !this.state.isVoid) || isBillingPayment) && hasRemainingAmount
            && (this.state.isCardlinkOne ? isTrxClosed : isTrxSettled)
        return (
            <React.Fragment>
                <div className={"all-details__wrapper " +
                    (!canRefund ? 'all-details__wrapper--no-refund-btn' : '')
                }
                >
                    <div className="transaction__details_modal-header--print transaction__details_modal-header">
                        <h4 className="modal-title">
                            <Translate value="pages.transactions.details.title"/>
                        </h4>
                    </div>
                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.transactionDate"/>
                            <span>{this.state.authorizationDate}</span>
                            <span>{this.state.authorizationTime}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.cardNumber"/>
                            <span>{this.state.maskedCardNumber}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.cardType"/>
                            <span
                                className="transaction__details__icon--no-print">{this.cardSchemeFormatter(this.state.cardTypeBrand)}</span>
                            <span
                                className="transaction__details--print-lbl">{lodash.startCase(lodash.toLower(this.state.cardTypeBrand))}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.amount"/>
                            <span>{formatAmount(I18n._locale, getSignedAmount(this.state.authorizationAmount, this.state.amountSign), 2) + euro}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.response"/>
                            <span>{this.state.response}</span>
                        </div>
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.merchantId"/>
                            <span>{this.state.merchantId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.terminalId"/>
                            <span>{this.state.terminalId}</span>
                        </div>
                        <div className="all-details__detailColumn vMerchant-col">
                            <Translate value="pages.transactions.details.vMerchantId"/>
                            <span>{this.state.vMerchantId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.vTerminalId"/>
                            <span>{this.state.vTerminalId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.bank"/>
                            {this.state.bank}
                        </div>
                    </div>
                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.merchantAlias"/>
                            <span>{this.state.merchantAlias ? this.state.merchantAlias : '-'}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.terminalAlias"/>
                            <span>{this.state.terminalAlias ? this.state.terminalAlias : '-'}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.tipsAmount"/>
                            <span>{formatAmount(I18n._locale, getSignedAmount(this.state.tipsAmount, 1), 2) + euro}</span>
                        </div>
                        {this.props.isEcom ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.ecommerceOrderId"/>
                                <span>{this.state.ecommerceOrderId ? this.state.ecommerceOrderId : '-'}</span>
                            </div>
                            : this.state.acquirerBankId === '014' ?
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.xlsId"/>
                                    <span>{this.state.xlsId ? this.state.xlsId : '-'}</span>
                                </div>
                                :
                                <div className="all-details__detailColumn">
                                </div>
                        }
                        {this.state.acquirerBankId === '014' && this.props.isEcom ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.xlsId"/>
                                <span>{this.state.xlsId ? this.state.xlsId : '-'}</span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn totalInstalments-col">
                            <Translate value="pages.transactions.details.totalInstallments" dangerousHTML/>
                            <span>{this.state.totalInstalments}</span>
                        </div>
                        <div className="all-details__detailColumn redemptionAmount-col">
                            <Translate value="pages.transactions.details.redemptionAmount"/>
                            <span>{formatAmount(I18n._locale, this.state.redemptionAmount, 2)}</span>
                        </div>
                        {this.props.isEcom !== 'ecom' ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.tillNumber"/>
                                <span>{this.state.tillNumber}</span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                        {this.props.isEcom !== 'ecom' ?
                            <div className="all-details__detailColumn cashier-col">
                                <Translate value="pages.transactions.details.cashierNumber"/>
                                <span>{this.state.cashierNumber}</span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                        {this.props.isEcom !== 'ecom' ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.paymentInfo"/>
                                <span>{this.state.paymentInfo}</span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.transactionType"/>
                            <span>{this.state.transactionType}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.transactionAttribute"/>
                            <span>{this.state.transactionAttribute}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.cardholderVerMode"/>
                            <span>{this.state.cardholderVerMode}</span>
                        </div>
                        <div className="all-details__detailColumn cardEntryMode-col">
                            <Translate value="pages.transactions.details.cardEntryMode" dangerousHTML/>
                            <span>{this.state.cardEntryMode}</span>
                        </div>
                        {this.props.isEcom !== 'ecom' ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.contactlessFlag"/>
                                <span>{this.state.contactlessFlag}</span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn authorizationCode-col">
                            <Translate value="pages.transactions.details.authorizationCode" dangerousHTML/>
                            <span>{this.state.authorizationCode}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.retrievalReferenceNumber"/>
                            <span>{this.state.retrievalReferenceNumber}</span>
                        </div>
                        <div className="all-details__detailColumn closeBatch-col">
                            <Translate value="pages.transactions.details.closeBatch"/>
                            <span>{this.state.closeBatch}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.transactions.details.settlementDate"/>
                            <span>{this.state.settlementDate}</span>
                        </div>
                        {this.props.isEcom === 'ecom' ?
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.status3D"/>
                                <span className={'' + (this.state.status3D ? 'icon-icon-check' : 'icon-x-icon')}></span>
                            </div>
                            :
                            <div className="all-details__detailColumn">
                            </div>
                        }
                    </div>

                    {this.state.infoField1 !== null && this.state.infoField1.trim() !== '' ? [
                        <div className="all-details__detail-row">
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.infoField1"/>
                                <span>{this.state.infoField1}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.infoField2"/>
                                <span>{this.state.infoField2}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.infoField3"/>
                                <span>{this.state.infoField3}</span>
                            </div>

                            <div className="all-details__detailColumn">
                            </div>
                            <div className="all-details__detailColumn">
                            </div>
                        </div>,
                    ] : null}

                    {this.state.dccFlag === true ?
                        <div className="all-details__detail-row">
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.dccAmount"/>
                                <span>{formatAmount(I18n._locale, this.state.dccAmount, 2) + euro}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.dccCurrency"/>
                                <span>{this.state.dccCurrency}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.dccRate"/>
                                <span>{formatAmount(I18n._locale, this.state.dccRate, 2)}</span>
                            </div>

                            <div className="all-details__detailColumn">
                            </div>
                            <div className="all-details__detailColumn">
                            </div>
                        </div> : null}

                    {this.state.cardlessType !== null ? [
                        <div className="all-details__detail-row"
                             key="transaction-details-row">
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.cardlessType"/>
                                <span>{this.state.cardlessType}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.originalTerminalId"/>
                                <span>{this.state.originalTerminalId}</span>
                            </div>
                            {this.state.cardlessType === 'pc' ?
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.paymentCode"/>
                                    <span>{this.state.paymentCode}</span>
                                </div> : null}
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.walletId"/>
                                {this.state.walletId}
                            </div>

                            <div className="all-details__detailColumn">
                            </div>
                            <div className="all-details__detailColumn">
                            </div>
                        </div>] : null}
                    {isApproved && this.props.isEcom !== 'ecom' && hasPermissionToRefund ?
                        <div className={"all-details__detail-row"}>
                            <div className="all-details__detailColumn remainingAmount-col">
                                <Translate value="pages.transactions.details.remainingAmount"/>
                                <span>{formatAmount(I18n._locale, this.state.remainingAmount, 2) + euro}</span>
                            </div>
                            <div className="all-details__detailColumn remainingAmount-col">
                                <Translate value="pages.transactions.details.refundedAmount"/>
                                <span>{formatAmount(I18n._locale, this.state.refundSum, 2) + euro}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.refundedOn"/>
                                <span>{this.state.refundedOn}</span>
                            </div>
                            <div className="all-details__detailColumn">
                                <Translate value="pages.transactions.details.refundedBy"/>
                                <span className="refunded-by__container">{this.state.refundedBy}</span>
                            </div>
                            <div className="all-details__detailColumn">
                            </div>
                        </div> : null}
                    {this.state.isCardlinkOne ?
                        <React.Fragment>
                            <div className={"all-details__detail-row"}>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.netCreditAmount"/>
                                    <NumberFormatter number={parseFloat(this.state.merchantFee)}
                                                     digits={2} symbol={"€"} symbolAsSuffix={true}/>
                                </div>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.totalCommissionAmount"/>
                                    <NumberFormatter number={parseFloat(this.state.commissionAmountMerchant)}
                                                     digits={2} symbol={"€"} symbolAsSuffix={true}/>

                                </div>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.cardlinkOneCommission"/>
                                    <NumberFormatter number={parseFloat(this.state.commissionAmountAcquirer)}
                                                     digits={2} symbol={"€"} symbolAsSuffix={true}/>
                                </div>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.processionCommission"/>
                                    <NumberFormatter number={parseFloat(this.state.commissionAmountAcquirerOthers)}
                                                     digits={2} symbol={"€"} symbolAsSuffix={true}/>

                                </div>
                                <div className="all-details__detailColumn">
                                </div>
                            </div>
                            <div className={"all-details__detail-row"}>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.paymentRequestDate"/>
                                    <span>{this.state.paymentReqDate}</span>
                                </div>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.paymentResponseDate"/>
                                    <span>{this.state.paymentRespDate}</span>
                                </div>
                                <div className="all-details__detailColumn">
                                    <Translate value="pages.transactions.details.paymentResponse"/>
                                    <Translate value={this.state.paymentRespMessage}/>
                                </div>
                                <div className="all-details__detailColumn">
                                </div>
                                <div className="all-details__detailColumn">
                                </div>
                            </div>
                        </React.Fragment>
                        : null}
                        <div className={'all-details__detail-row'}>
                            <div className='all-details__detailColumn'>
                                <Translate value='pages.transactions.details.loyaltyProgram' />
                                <span>{this.state.loyaltyProgram ? this.state.loyaltyProgram : '-'}</span>
                            </div>
                            <div className='all-details__detailColumn'>
                                <Translate value='pages.transactions.details.wallet' />
                                <span>{this.state.wallet ? this.state.wallet : '-'}</span>
                            </div>
                            <div className='all-details__detailColumn'>
                            </div>
                            <div className='all-details__detailColumn'>
                            </div>
                            <div className='all-details__detailColumn'>
                            </div>
                        </div>
                    <div className={"all-details__print-buttons-row"}>
                        <Overlay
                            show={this.props.showEmailWindow}
                            target={this.state.target}
                            placement="top"
                            container={this}
                            containerPadding={20}>
                            <Popover id="transaction-details-receipt-email-popover">
                                <div className="popover__receipt-modal">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        this.props.toggleEmailWindow()
                                        this.props.sendReceiptPdfFileEmail()
                                    }}>
                                        <div className="business-column">
                                            <Translate value="pages.transactions.details.emailPopover.title"
                                                       className="editable-popover-label"/>
                                            <button className="icon-x-icon" type="button"
                                                    onClick={this.props.toggleEmailWindow}/>
                                            <div className="receipt-modal-row">
                                                <input type="text" id="receiptEmail" name="receiptEmail"
                                                       className="simple-input"
                                                       onChange={(event) => {
                                                           this.props.changeReceiptEmail(event)
                                                       }}
                                                       value={this.props.receiptEmail}
                                                       placeholder={this.props.receiptEmail}
                                                       title={this.props.receiptEmail}/>
                                                <button className="icon-saita" type="submit"
                                                        disabled={this.props.disableExport || !this.props.validEmail || !this.props.receiptEmail || this.props.receiptEmail.length === 0}/>
                                            </div>
                                            {!this.props.validEmail ?
                                                <div className="support-error-file">
                                                    <span className="icon-icon-attention support-error-file__icon"/>
                                                    <Translate className="support-error-file__text"
                                                               value="pages.transactions.details.emailPopover.errorMessage"/>
                                                </div> : null}
                                        </div>
                                    </form>
                                </div>
                            </Popover>
                        </Overlay>
                        {this.props.disableExport ?
                            <Loader bouncing={true}
                                    color={'cerulean'}
                                    loaderStyles={{minHeight: "100vh", margin: "0"}}
                                    message={this.props.loaderTitle}
                                    subMessage={''}
                                    style={{top: "115px"}}
                                    overlayClass="is-overlay"/> : null
                        }
                        <GenericModal
                            showModal={this.props.receiptExportError}
                            showCloseButton={true}
                            onHide={this.props.hideReceiptExportError}
                            modalTitle={<Translate value={"pages.transactions.details.receiptFileError.title"}/>}
                            modalBody={<Translate value={"pages.transactions.details.receiptFileError.message"}/>}
                            bsSize="small"/>
                    </div>
                    {canRefund ?
                        <React.Fragment>
                            <div className="all-details__refund-row transaction__details--refund">
                                <Button className="all-details__refund-row__refund-button"
                                        onClick={this.toggleRefundWindow}
                                        disabled={!this.authorizationNotOlderThan1Year()
                                            || this.cardlessTransAndStatusNot1() || isImpersonator || this.props.isEcom}>
                                    <Translate value="pages.transactions.details.refundButton"/>
                                </Button>
                                <Tooltip placement="right"
                                         tooltipButtonId="refundTooltip"
                                         popoverId="refund-tltp"
                                         tooltipLabelClass="refund-label"
                                         tooltipText="refund"
                                         dangerousHtml={true}
                                />
                            </div>
                            <Overlay
                                show={this.state.showRefundWindow}
                                target={this.state.target}
                                placement="top"
                                container={this}
                                containerPadding={20}>
                                <Popover id="transaction-details-popover" title=''>
                                    <div>
                                        <div className="all-details__refund-modal__text">
                                            <Translate value="pages.transactions.details.refundWindow.text1"/>
                                        </div>
                                        <div className="all-details__refund-modal__text">
                                            <Translate value="pages.transactions.details.refundWindow.text2"/>
                                        </div>
                                    </div>
                                    <div className="all-details__refund-modal__amount-textbox-wrapper">
                                        <NumberFormat
                                            type="text"
                                            decimalSeparator={I18n._locale === "el" ? "," : "."}
                                            thousandSeparator={I18n._locale === "el" ? "." : ","}
                                            maxlength="12"
                                            decimalScale={2}
                                            allowNegative={false}
                                            required={true}
                                            onValueChange={this.handleFormattedChange}
                                            className="all-details__refund-modal__amount-textbox"
                                            placeholder=""
                                            id="amountToRefund"
                                            value={formatAmount(I18n._locale, this.state.amountToRefund, 2)}/>
                                        <Translate className="all-details__refund-modal--currency-label"
                                                   value='pages.transactions.details.refundWindow.currencyLabel'/>
                                    </div>
                                    <div className="all-details__refund-modal__button-wrapper">
                                        <Button className="all-details__refund-modal__button-wrapper__cancel-button"
                                                onClick={() => {
                                                    this.toggleRefundWindow()
                                                }}>
                                            <Translate value="pages.transactions.details.refundWindow.cancel"/>
                                        </Button>
                                        <Button className="all-details__refund-modal__button-wrapper__refund-button"
                                                onClick={() => {
                                                    this.toggleRefundWindow()
                                                    this.props.toggleRefundModal(this.state.amountToRefund,
                                                        this.state.authorizationId)
                                                }}>
                                            <Translate value="pages.transactions.details.refundWindow.send"/>
                                        </Button>
                                    </div>
                                </Popover>
                            </Overlay>
                        </React.Fragment> : null}
                </div>
            </React.Fragment>
        )
    }
}


export default connect(stateMap)(Details);

