import React from 'react';
import ReactDOM from 'react-dom';
import App from "./js/components/mainFrame/App";
import {Provider} from 'react-redux';
import store from './js/store';
import registerServiceWorker from './registerServiceWorker';
import './assets/scss/Main.scss';

ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>
    ,document.getElementById("main-app"));
    
    
registerServiceWorker();
