export default {
    dropdownCompanies: "All companies",
    dropdownStores: "Select a store (MID)",
    mainMultipleMID: "Add MIDs in order to see info only for those stores.",
    socialMedia: "Follow us on social media",
    startScreen: {
        welcome: "Welcome to",
        cardlinkPortal: "Cardlink apollo",
        selectHomepageLabel_a: "I want",
        selectHomepageLabel_b: " Dashboard",
        selectHomepageLabel_c: " to become",
        selectHomepageLabel_d: " my initial page at Cardlink apollo",
        modal: {
            caption: "Welcome to our renewed Portal, Cardlink apollo, the valuable tool of your company!",
            text: "Cardlink apollo provides you with valuable information about your transactions and POS with statistics and useful reports."
        },
        option: {
            overview: {
                title: "Dashboard",
                text: "Click here and find useful information at a glance!"
            },
            business: {
                title: "Company",
                text: "Find information about your company's cooperation with Cardlink."
            },
            transactions: {
                title: "Transactions",
                text: "Here you will find all the information about your transactions."
            },
            reports: {
                title: "Reports",
                text: "View statistics and reports."
            }
        }
    },
    pages: {
        login: {
            portal: "Cardlink apollo",
            tool: "The most valuable tool of your company!",
            everyDay: "Every day by your side with:",
            listItem1: "Detailed information about your transactions and products",
            listItem2: "Useful reports",
            listItem3: "Effective and immediate assistance and support",
            email: "Email",
            emailPlaceholder: "  your email",
            capsLockEnabled: "[Capital letters]",
            wrongCreds: " Wrong username/password. <br/>",
            wrongCreds2: " Try again or click ‘Forgot password’ to reset it.",
            notAllowedIp: "This account is not accessible through the specific IP.",
            password: "Password",
            passwordPlaceholder: " your password ",
            buttonText: "Login",
            forgotPassword: "Forgot password?",
            newUser: "New user?",
            nonConfirmedAccount: " Your account is not activated.",
            disabledAccount: " Your account is deactivated.",
            roleNotMerchant: " Only registered users have access",
            registerIn5Steps: "Registration in 3 steps",
            nonMerchantModalMsgA: "To log in at Cardlink Portal, please use url: ",
            nonMerchantModalMsgB: " and your current username and password.",
            impersonator: {
                termsNotAccepted: "This specific user has not yet accepted the latest terms and conditions, logout is taking place...",
                expiredPassword: "The password of this specific user has expired, logout is taking place...",
                genericError: "An error occurred, logout is taking place..."
            }
        },
        reset: {
            help: "Please contact us at support@cardlink.gr or at 2106303000",
            needHelp: "Do you need help?",
            codeNotReceived: "Is there a problem with the 4-digit code?",
            email: "Email",
            portal: "Cardlink apollo",
            resetYourPass: "Reset Password",
            step1: "STEP 1",
            step2: "STEP 2",
            fillForm: "Fill in your email and mobile number to receive via email the reset pasword link and via sms a security code.",
            emailPlaceholder: "your email",
            mobilePhone: "Mobile Phone",
            buttonText: "Submit",
            resendButton: "Resend code",
            returnToLogin: "Return to login page",
            mobilePhonePlaceholder: "69",
            enter4DigitCode: "Enter the 4-digit code you received via SMS. Enter your new password and login to Cardlink apollo.",
            passwordPlaceholder: "your password",
            newPassword: "New password",
            retypeNewPassword: "Confirm new password",
            fourDigitCode: "4-digit code",
            sendFourDigitCode: "Send code",
            allowFewSeconds: "Please wait a few seconds to receive the code. In case of code expiration, click Resend. If you have not received it, please go back to Step1 and check the correctness of your mobile number.",
            allowFewSeconds5: "&#9679; It might take a few seconds to reveive the 4-digit code.<br/> &#9679; If you have not received it or if it is expired, click Resend to receive a new one.<br/> &#9679; However, if you have not received the code yet, please go back to <a href=\"/reset\"><u>Step1 </u></a> and check the correctness of your mobile number.If the problem persists, please contact us at 2106303000",
            allowFewSeconds1: "It might take a few seconds to receive the SMS with the 4-digit code.",
            allowFewSeconds2: "If you have not received the 4-digit code or if it is expired, click Resend to receive a new one.",
            allowFewSeconds3: "However, if you have not received the code yet, please go back to <a href=\"/reset\"><u>Step1 </u></a> and check the correctness of your mobile number.If the problem persists, please contact us at <i>2106303000</i>",
            matchRequestedFormat: "Please match the requested format",
            passwordRequirements: "Your password must contain at least 12 characters, latin letters (capital and small), numbers and special characters",
            phoneRequirements: "",
            passwordsNotMatch: "Passwords do not match",
            successfulPassChange: "Succesful change of password",
            youcanNowLogin: "You can now use your new password to login to Cardlink apollo",
            errors: {
                emailNotification: "If you are a registered user, you will receive soon an email, with a link to reset your password.",
                invalidNotification: "An error occurred, please try again later.",
                anErrorOccured: "An error occurred, please try again later or contact us at support@cardlink.gr or at 210 630 3000",
                codeNotFoundOrExpired: "This link has expired",
                otpInvalid: "The 4-digit code you entered is not correct.",
                otpLimitReached: 'Maximum attempts number reached. The 4-digit code has been expired. Please wait a few seconds to receive a new code.',
                unsuccessfulReset: "Authentication Failed",
                successfulReset: "Successfully sent",
                successfulReset2: "Please check your email and your mobile inbox"
            }
        },
        register: {
            sealSizeSpecifications: "The image size must not exceed 4MB",
            sealTypeSpecifications: "Please upload an image file of the supported types (jpg, png or gif)",
            emailExists: "Email: %{email} is already registered.",
            afmMidNotFound: "Please check the data you entered. The MID does not correspond to this Tax ID.",
            duplicateMid: "Typing the same MID more than once is not allowed.",
            imageMissing: " Please add an image",
            nameError: "The name and surname of the Responsible and Legal Representative are different whereas the email address is the same",
            phoneError: "The mobile number of the Responsible and Legal Representative are different whereas the email address is the same",
            invalidTaxId: "Invalid Tax ID",
            internalError: "An error occurred",
            inputError: 'An error occurred. Please check the data you entered.',
            acceptTerms: "You have to accept the Terms and Conditions.",
            portal: "Cardlink apollo",
            registerMerchant: 'Register at the service',
            legalRepCaption: 'A company can be registered at Cardlink apollo only if the registration form is completed and submitted by its Legal Representative.',
            legalRepPositive: 'I am the Legal Representative',
            legalRepStatement: 'You stated that you are the Legal Representative',
            returnToLogin: "Go to login page",
            submitButton: "Next",
            registerButton: "Register",
            backButton: "Back",
            addUser: "User addition",
            removeUser: "Remove User",
            addMerchantId: "Add MID",
            removeMerchantId: "Remove MID",
            title1: "Legal Representative Data",
            subTitle1: 'For your company\'s registration you will only need:',
            listItem1: 'The company\'s VAT number',
            listItem2: 'The MID code/s',
            listItem3: 'A copy of the company’s stamp in jpg, png or gif file type',
            title2: "Company Data",
            corpType: "Legal Form",
            fa: "Sole proprietorship or Freelancer",
            co: "Corporate (LLC, SA, GP, PC, etc.)",
            corpTypeError: "Please choose a corporation type",
            subTitle2: "For the registration, you have to upload a copy of your company stamp (jpg, png or gif, size < 4MB).",
            subTitle2b: "If you want to register for more than 5 MIDs, please contact us at support@cardlink.gr or at 2106303000.",
            title3: "Authorized users",
            subTitle3: "Here you can enter the authorized users who will have access to Cardlink apollo ",
            title4: "Responsible User",
            subTitle4: "Here you will state the user data of the person who will be able to deactivate the access of any company user, in case of username/password leakage. ",
            title5: "Registration Completion",
            approveTermsPart1: "I unconditionally accept the  ",
            approveTermsPart2: "<b>Terms and Conditions of Cardlink apollo</b>,",
            approveTermsPart3: " which I have read and are also available at www.cardlink.gr, as well as the processing of my personal data for purposes related to the use of the Platform.",
            approveSecondCheck: "I\nassume sole responsibility for the accuracy of the information referred to\nabove.",
            step1: "Step 1",
            step2: "Step 2",
            step3: "Step 3.1",
            step4: "Step 3.2",
            yes: "Yes",
            no: "No",
            emailPlaceholder: "email",
            lastNamePlaceholder: "Surname",
            firstNamePlaceholder: "Name",
            afmPlaceholder: "Tax ID",
            companyNamePlaceholder: "Company Name",
            companyTitlePlaceholder: "Company Discreet Title",
            merchantIdPlaceholder: "Merchant ID (MID)",
            afm: "TaxID",
            merchantNameLatin: "Company Name",
            merchantTitleLatin: "Discreet Title",
            mid: "Merchant ID",
            user1: "User #1",
            user2: "User #2",
            user3: "User #3",
            user4: "User #4",
            user5: "User #5",
            legalAlert: "Only the Legal Representative can submit the registration request to Cardlink apollo.",
            thankYou: "Thank you.",
            ok: "OK",
            addSeal: "Upload company's stamp",
            successfulMerchantRegistration: "We have received your registration application for Cardlink apollo and we are processing it.\n" +
                "Within the next two working days we will inform you about its progress.\n" +
                "Thank you!",
            successfulMerchantRegistrationTitle: "Successful submission!",
            fileName: "Filename: ",
            imageIcon: "Image: ",
            imageHint: "Place the stamp inside" +
                " the white frame. If necessary, use the <i class='icon-plus-accordion'></i> <i class='icon-minus-accordion'></i> buttons to adjust its size.",
            zoomIn: "Zoom in",
            zoomOut: "Zoom out",
            responsibleUserLegalRep: "As the legal representative of the company, I also desire to be the responsible user.",
            responsibleUserOther: "As the legal representative, I would like to designate another user as the responsible user."
        },
        expiredPassword: {
            title: "Your password has expired!",
            caption: "For security reasons, we suggest you to change often your Cardlink apollo password.",
            changePassword: "To continue, please change your password.",
            old: "Previous password",
            new: "New password",
            confirm: "Confirm new password",
            passwordRequirements: "Your password must contain at least 12 characters, latin letters (capital and small), numbers and special characters",
            saveButton: "Submit",
            succes: {
                passwordChangedSuccessfully: "Successful password change!"
            },
            errors: {
                passwordIsIncorrect: "Wrong password",
                passwordSameAsConfirm: "Passwords do not match",
                passwordNotChanged: "An error occurred. The new password is not saved.",
                oldPasswordError: "Old password is wrong",
                commonPasswordError: "Password is in the list of the most commonly used passwords.",
                midPasswordError: "Password must contain at least 12 characters, latin letters (capital and small), numbers and special characters.",
                samePasswordError: "You cannot use the old password again",
                passwordHistoryError: "Please choose a new password that you haven't used before."
            }
        },
        invalidInvitation: {
            title: {
                expired: "Your invitation is expired",
                wrongParam: "Wrong invitation data"
            },
            message: {
                expired: "You should receive a new invitation. Please contact us at support@cardlink.gr or at 2106303000 for any inquiry.",
                wrongParam: "Confirm that the link is correct. Contact us at support@cardlink.gr or at 2106303000 for any inquiry."
            }
        },
        userDetails: {
            newPassword: "Create new password",
            retypeNewPassword: "Confirm new password",
            passwordRequirements: "Password must contain at least 12 characters, latin letters (capital and small), numbers and special characters",
            passwordsNotMatch: "Passwords do not match",
            capsLockEnabled: "[Capital letters]",
            submitButtonText: "Submit",
            mobilePhone: "Mobile Phone",
            resendButton: "Resend",
            allowFewSeconds: "Please wait a few seconds to receive the code. In case of code expiration, click Resend. If you have not received it, please go back to Step1 and check the correctness of your mobile number.",
            returnToPhoneForm: "Go back",
            fourDigitCode: "4-digit code",
            errors: {
                invalidEmailOrConfirmationToken: "Invalid Email Or Confirmation Token",
                invalidOtp: "The 4-digit code you entered is wrong.",
                errorOccurredTitle: "An error occurred",
                anErrorOccured: "An error occurred, please try again later or contact us at support@cardlink.gr or at 210 630 3000"
            }
        },
        overview: {
            selectStore: "View Reports per store",
            dashboard: {
                title: "Key Performance Indicators (KPI's)",
                transactionsInfo: {
                    amount: "Transactions amount",
                    count: "Transactions count",
                    timespanLabels: {
                        prePrevDay1: "In comparison to",
                        prePrevDay2: "the previous day",
                        prePrev7Days1: "In comparison to",
                        prePrev7Days2: "the previous 7 days",
                        prePrev30Days1: "In comparison to",
                        prePrev30Days2: "the previous 30 days",
                        prePrev90Days1: "In comparison to",
                        prePrev90Days2: "the previous 90 days",
                        prePrev180Days1: "In comparison to",
                        prePrev180Days2: "the previous 180 days",
                        prePrev365Days1: "In comparison to",
                        prePrev365Days2: "the previous year"
                    },
                    dropdownBaseValue: "Previous date"
                },
                gauge: {
                    kpiLabel: "TRANSACTION VALUE",
                    installmentsLabel: "INSTALLMENT VALUE",
                    valueLabels: {
                        min: "MIN",
                        average: "Average",
                        max: "MAX"
                    }
                }
            },
            barChart: {
                diagramTitle: "TRANSACTIONS COUNT & AMOUNT ",
                column1Title: "Transaction Amount",
                column2Title: "Transactions Count"
            },
            moreReports: "More Reports",
            gotoTransactions: "View transactions",
            pos: {
                title: "My Cardlink POS",
                functions: {
                    operative: "Active",
                    broken: "Inactive",
                    returns: "To be returned",
                    activation: "To be activated",
                    lost: "Untraced"
                }
            },
            supportForm: {
                supportButton: "SUPPORT",
                faq: "FAQs",
                needHelp: "Need help?",
                phoneNumber: "210 630 3000",
                contactEmail: "support@cardlink.gr",
                otherWay: "Send your request",
                orderPaperRolls: "Order <br/> paper rolls",
                here: " here "
            },
            faqForm: {
                noPrint: "POS does not print receipts",
                noButtonWorks: "POS buttons do not function",
                telApproval: "How do I get an authorization code via phone?",
                procurementIssues: "Whom should I contact for transaction fee inquiries?",
                whitePaper: "POS prints blank paper",
                charge: "How often should I charge my POS?",
                anyMoreQuestions1: "Any further questions?",
                anyMoreQuestions2: "Find more answers here"
            }
        },
        business: {
            details: "Company Data ",
            addLogoTitle: "ADD LOGO ",
            addLogoDescr: "(jpeg, png or gif < 4Mb)",
            logoTypesSupported: "in one of the following formats jpeg, png or gif up to 4MB",
            afm: "TAX ID",
            doy: "TAX OFFICE",
            iban: 'IBAN',
            title: "COMPANY DISCREET TITLE",
            name: "COMPANY NAME",
            legalRep1: "1st REPRESENTATIVE DATA",
            legalRep2: "2nd REPRESENTATIVE DATA",
            legalRep: "Company Legal Representatives ",
            legalRepName: "NAME",
            legalRepAdt: "TAX ID NUMBER",
            legalRepAfm: "TAX ID",
            legalRepFatherName: "FATHER'S NAME",
            legalRepTelephone: "TELEPHONE",
            eshopButton: "E-Shop",
            eshopDropdown: "E-Shop",
            popover: {
                merchantAlias: "Alias modification",
                postCode: "Post code modification",
                terminalAlias: "Alias modification"
            },
            stores: {
                title: "Stores (MIDs)",
                hint: "Select MIDs in order to see info only for those stores."
            },
            midDetails: {
                title: "Physical Store Info",
                singleTitle: "Physical Store Info",
                ecomTitle: "E-shop Info",
                midTitle: "MERCHANT ID (MID)",
                midAlias: "ALTERNATIVE NAME (ALIAS)",
                midActivity: "ACTIVITY",
                address: "ADDRESS",
                postCode: "POST CODE",
                city: "CITY",
                area: "REGION",
                phone1: "TELEPHONE",
                phone2: "TELEPHONE #2",
                phone3: "TELEPHONE #3",
                mobilePhone: "MOBILE PHONE",
                banks: "COOPERATION",
                provideAlias: "Set Alias",
                contactDetails: "Address & Contact details",
                providePostalCode: "Set Post Code",
                emptyCity: "Unknown location"
            },
            paymentsAndFees: {
                title: "Cardlink one Payment & Fees",
                caption: "PAYMENT ACCOUNT",
                fixRate: "COMMISSION(€)",
                percentageRate: "COMMISSION (%)",
                table: {
                    card: "CARD",
                    type: "TYPE",
                    fixRate: "COMMISSION (€)",
                    percentageRate: "COMMISSION (%)",
                    noData: "There is no data to display."
                }
            },
            debitNotes: {
                title: 'Cardlink one Debit Notes',
                new: 'NEW',
                form: {
                    monthSelection: "Debit Note",
                    moreCriteria: "ANALYSIS BY CHOOSING EXTRA CRITERIA",
                    lessCriteria: "WITHOUT EXTRA CRITERIA",
                    timePeriod: "Time period",
                    posSelection: "Select POS (TID)",
                    allPos: "All POS",
                    cardSchemeSelection: "Select card scheme",
                    allCardSchemes: "All card schemes",
                    batchSelection: "Select batch",
                    allBatches: "All batches",
                    submit: "Submit"
                },
                overview: {
                    table: {
                        title: 'Table of transaction fees totals per card scheme',
                        total: 'TOTAL'
                    },
                    chart: {
                        title: 'Percentage ratio of the total amount of transaction fees',
                        tableTitle: 'Percentage %'
                    }
                },
                details: {
                    title: "DETAILED TRANSACTIONS ANALYSIS",
                    table: {
                        headings: {
                            date: "Transaction<br/>Date",
                            tid: "POS",
                            batch: "Batch",
                            cardScheme: "Card scheme",
                            salesAmount: "Nominal<br/>sales amount",
                            refundsAmount: "Refunds<br/>amount",
                            commissionAmount: "Commission<br/>amount",
                            netAmount: "Net amount"
                        },
                        pagination: {
                            nextPageTitle: "Next page",
                            prevPageTitle: "Previous page",
                            firstPageTitle: "First page",
                            lastPageTitle: "Last page",
                            results: "Results",
                            toLabel: "to",
                            jumpToPage: "Jump to page"
                        },
                    }
                }
            },
            tidDetails: {
                title: "POS",
                ecomTitle: "E-POS",
                dropdownsActivePosLabel: "Active POS search",
                dropdownsInactivePosLabel: "Inactive POS search",
                emptyActiveDropdown: "No TIDs",
                emptyInactiveDropdown: "No TIDs",
                activeDropdown: "Select a POS",
                inactiveDropdown: "Select a POS",
                tidTitle: "POS ID (TID)",
                tidAlias: "ALTERNATIVE NAME (ALIAS)",
                serialNumber: "SERIAL NUMBER",
                status: "POS STATUS",
                model: "POS MODEL",
                type: "TYPE",
                technology: "TECHNOLOGY",
                contactless: "CONTACTLESS TRANSACTIONS",
                pinPad: "PIN PAD",
                ecr: "ELECTRONIC CASHIER REGISTER",
                interconnection: "CONNECTION METHOD",
                collaboration: "COOPERATION",
                conformityProgram: "LOYALTY PROGRAM",
                dynamicConversion: "DYNAMIC CURRENCY CONVERSION",
                activeLabel: "ACTIVE",
                inactiveLabel: "INACTIVE"
            },
            billing: {
                billingTitle: "Pricing Policy",
                posAcquisition: "POS ACQUISITION",
                monthlyServiceFee: "SERVICE FEE",
                rentalFee: "RENTAL FEE",
                billingPeriod: "BILLING PERIOD",
                initialBillingPeriod: "INITIAL BILLING PERIOD",
                futureBilling: "FUTURE BILLING"
            },
            logoRestrictions: "Logo image size must be less than 4MB, with maximum height of 80px and length of 130px",
            modal: {
                closed: "Download store excel",
                opened: "Close",
                reset: "Clear",
                selectMid: "Specific store (MIDS)",
                midTitle: "MID + enter",
                selectTid: "Select POS (TID)",
                tidTitle: "TID + enter",
                onlyActive: "Only active POS",
                submit: "Download",
                companyDlMultipleMid: "Add MIDs in order to see info only for those stores.",
                companyDlMultipleTid: "Add TIDs in order to see info only for those stores."
            }
        },
        transactions: {
            tabs: {
                store: {
                    title: "<i class=\"icon-icon-pos\"></i>Store Transactions"
                },
                ecommerce: {
                    title: "<i class=\"icon-icon-ecommerce\"></i>E-Commerce Transactions"
                },
                installments: {
                    title: "<i class=\"icon-logo-cardlink\"></i>Cardlink One Installments"
                }
            },
            tooltips: {
                trxItemDropdown: "Transactions per page",
                excelButtonTooltip: "Send transactions to email"
            },
            searchModal: {
                labels: {
                    opened: "Search",
                    closed: "Close Search",
                    clear: "Clear"
                },
                leftColumn: {
                    timePeriod: "Time period"
                },
                midColumn: {
                    pickStore: "Select store (MID)",
                    searchRange: "Search by MID",
                    toAllStores: "All stores (MIDs)",
                    toSpecificStores: " Selected MIDs",
                    allPos: "All POS",
                    pickPos: "Select POS (TID)",
                    singlePos: "POS",
                    range: "Transaction amount range",
                    from: "From",
                    to: "To"
                },
                rightColumn: {
                    pickTransType: "Select transaction type",
                    allTransTypes: "All types",
                    transStatus: "Transaction status",
                    transSuccess: "Approved",
                    transReject: "Not Approved"
                },
                lowerMidColumn: {
                    pickBank: "Select Payment Provider",
                    allBanks: "All Payment Provider",
                    pickVmid: "Select VMID",
                    allVmids: "All Virtual Merchant IDs",
                    pickVtid: "Select VTID",
                    allVtids: "All Virtual Terminal IDs"
                },
                lowerRightColumn: {
                    pickPayType: "Payment method",
                    allPayTypes: "All methods",
                    transProgress: "Transaction progress",
                    allTransProgress: "All transactions",
                    transPending: "Approvals/Rejections",
                    transCleared: "To be settled",
                    transClosedBatch: "Closed batch",
                    transSuccessPay: "Successful credits",
                    transUnsuccessPay: "Unsuccessful credits",
                    duplicateTransactions: 'Duplicate Transactions',
                    showDuplicateTransactions: 'Possible duplicate charges'
                },
                lowerLeftColumn: {
                    moreCriteria: "MORE CRITERIA",
                    lessCriteria: "LESS CRITERIA",
                    cardNumber: "Card Number",
                    cardNumberPlaceholder: "XXXX XXXX XXXX XXXX",
                    cardNumberTooltip: "If you do not have the card number available, use the wild card (*). The asterisk replaces one or more numbers and can be used in any position.",
                    cardNumberErrorTitle: "Wrong data",
                    retrievalNumber: "Retrieval Ref. Number",
                    retrievalNumberPlaceholder: "13 digits",
                    approvalCode: "Approval Code",
                    approvalCodePlaceholder: "6 digits"
                },
                installments: {
                    searchTrxId: "Search by Transaction ID",
                    trxId: "Transaction ID",
                    trxIdTooltip: "Add the transaction ID of the original transaction.",
                    trxIdErrorTitle: "Wrong data",
                },
                button: {
                    search: "Search"
                }
            },
            table: {
                headings: {
                    dateAndTime: "Date <br/>Time",
                    merchantId: "Merchant ID <br/>(MID)",
                    terminalId: "Terminal ID <br/>(TID)",
                    scheme: "Card",
                    wallet: "Wallet",
                    cardNumber: "Card<br/>Number",
                    bank: "Payment<br/>Provider",
                    transactionType: "Transaction<br/>Type",
                    totalInstallments: "Installments<br/>",
                    redemptionAmount: "Redemption<br/>Amount",
                    authorizationAmount: "Transactions<br/>Amount",
                    status3d: "3D Secure",
                    status: "Response"
                },
                pagination: {
                    nextPageTitle: "Next page",
                    prevPageTitle: "Previous page",
                    firstPageTitle: "First page",
                    lastPageTitle: "Last page",
                    results: "Results",
                    toLabel: "to",
                    jumpToPage: "Jump to page"
                },
                transactionTypes: {
                    purchase: "Purchase",
                    preAuth: "PreAuth",
                    refund: "Refund",
                    voidOfRefund: "Void Of Refund",
                    voidOfAsfGonea: "Void Of Asf Gonea",
                    preAuthCom: "PreAuth Completion",
                    mailPhone: "Mail/Phone Order",
                    transVoid: "Void",
                    reversal: "Reversal",
                    revAuto: "Reversal Automated",
                    asfalistikosGoneas: "Asfalistikos Goneas",
                    utilityPayment: "Utility Payment",
                    utilityPaymentVf: "Utility Payment-VF",
                    utilityPaymentFa: "Utility Payment-FA",
                    prepaidBalance: "Prepaid Balance",
                    prepaidCredit: "Prepaid Credit",
                    prepaidRedemption: "Prepaid Redemption",
                    balanceInquiry: "Balance Inquiry",
                    unknown: "Unknown acquirer"
                },
                emptySearchMessageTitle: "Your search did not return any results. ",
                emptySearchMessageText: "Try with different criteria.",
                emptyTableMessageTitle: "There are no transactions in the specified time period.",
                errorTableMessageTitle: "An error occurred during data collection."
            },
            details: {
                title: "Transaction Details",
                transactionDate: "TRANSACTION DATE",
                cardNumber: "CARD NUMBER",
                cardType: "CARD TYPE",
                amount: "TRANSACTION AMOUNT",
                approved: "Approved",
                declined: "Not Approved",
                declinedCardlinkOne: 'Not approved - Company’s limit reached',
                response: "RESPONSE",
                merchantId: "MERCHANT ID (MID)",
                terminalId: "TERMINAL ID (TID)",
                merchantAlias: "ALTERNATIVE MERCHANT ID (MID) NAME",
                terminalAlias: "ALTERNATIVE POS ID (TID) NAME",
                vMerchantId: "VMERCHANT ID (VMID)",
                vTerminalId: "VTERMINAL ID (VTID)",
                bank: "COOPERATION",
                totalInstallments: "# NUMBER </br>OF TOTAL TRANSACTIONS",
                redemptionAmount: "REDEMPTION AMOUNT",
                tillNumber: "# TILL",
                cashierNumber: "# CASHIER",
                paymentInfo: "SUBSCRIBER CODE",
                transactionType: "TRANSACTION TYPE",
                transactionAttribute: "TRANSACTION ATTRIBUTE",
                cardholderVerMode: "CARDHOLDER VERIFICATION MODE",
                cardEntryMode: "CARD </br>ENTRY MODE",
                contactlessFlag: "CONTACTLESS FLAG",
                authorizationCode: "AUTHORIZATION </br>CODE",
                retrievalReferenceNumber: "RETRIEVAL REFERENCE NUMBER",
                closeBatch: "CLOSE BATCH",
                settlementDate: "IN SETTLEMENT FILE",
                status3D: "3D SECURE",
                infoField1: "INFO FIELD #1",
                infoField2: "INFO FIELD #2",
                infoField3: "INFO FIELD #3",
                dccAmount: "DCC AMOUNT",
                dccCurrency: "DCC CURRENCY",
                dccRate: "DCC RATE",
                cardlessType: "CARDLESS",
                originalTerminalId: "ORIGINAL TID",
                paymentCode: "CODE",
                walletId: "WALLET ID",
                tipsAmount: "TIPS AMOUNT",
                ecommerceOrderId: "E-COMMERCE ORDER ID",
                xlsId: "LOYALTY ID",
                loyaltyProgram: "LOYALTY PROGRAM",
                wallet: 'WALLET',
                refundedAmount: "REFUNDED AMOUNT",
                remainingAmount: "REMAINING AMOUNT",
                refundedOn: "REFUNDED ON",
                refundedBy: "REFUNDED BY",
                refundButton: "Refund",
                netCreditAmount: `NET CREDIT\nAMOUNT (MERCHANT)`,
                totalCommissionAmount: `TOTAL COMMISSION\nAMOUNT`,
                cardlinkOneCommission: `CARDLINK ONE\nCOMMISSION`,
                processionCommission: `PROCESSOR\nCOMMISSION`,
                paymentRequestDate: `PAYMENT SENT TO\nMERCHANT'S BANK DATE`,
                paymentResponseDate: `MERCHANT'S BANK\nRESPONSE DATE`,
                paymentResponse: `MERCHANT'S BANK\nRESPONSE`,
                transactionNotSettled: "The transaction is not settled yet",
                transactionOlderThan1Year: "Refund transaction can't be completed if 1 year have passed after the initial transaction",
                printDetails: "Print transaction details",
                printReceipt: "Download Transaction e-Receipt",
                shareReceipt: "Share transaction e-Receipt",
                cardlinkOneSuccessfulPayment: "SUCCESSFUL PAYMENT",
                cardlinkOneUnsuccessfulPayment: "UNSUCCESSFUL PAYMENT",
                refundWindow: {
                    currencyLabel: "EUR",
                    text1: "The amount which will",
                    text2: "be refunded to the cardholder",
                    cancel: "Cancel",
                    send: "Submit Refund"
                },
                modalWindow: {
                    text1: "Do you want to refund",
                    text2: "%{amountToRefund} EUR",
                    text3: "to the cardholder?",
                    yes: "Yes",
                    cancel: "Cancel",
                    refundNotSucceeded: "Refund is not completed",
                    refundSucceeded: "Refund is successfully completed"
                },
                emailPopover: {
                    title: "Type a valid e-mail",
                    errorMessage: "The e-mail is not valid."
                },
                receiptFileError: {
                    title: 'An error occurred',
                    message: 'Pleas try again.'
                }
            },
            excelReport: {
                AnErrorOccurred: "An error occurred",
                largeAmountOfDataError: "Please reduce the count of the transactions through the search criteria."
            }
        },
        installments: {
            details: {
                title: "Installment Details",
                merchantId: "Merchant ID",
                terminalId: "Terminal ID",
                id: "Installment ID",
                trxId: "Transaction ID",
                amount: "Amount",
                merchantFee: "Net credit amount (Cardlink One)",
                commissionAmountMerchant: "Total commission",
                number: "Installment Number",
                paymentDate: "Payment Date",
                status: "Status",
                paymentReqDate: "Payment sent to Merchant’s Bank date",
                paymentRespDate: "Merchant’s Bank credit date",
                paymentRespMessage: "Merchant’s Bank response",
                debitNoteNumber: "Debit Note Number",
                debitNoteGenerationDate: "Debit Note Generation Date",
                debitNoteScheme: "Debit Note Scheme",
                debitNoteProduct: "Debit Note Product",
                debitNoteMonth: "Debit Note Month",
                debitNoteYear: "Debit Note Year",
                createDatetime: "Creation Datetime",
                updateDatetime: "Update Datetime",
                statusDescription: {
                    1: "Installment registered",
                    2: "Installment settled",
                    3: "Sent for credit",
                    4: "Installment credited"
                }
            },
            table: {
                headings: {
                    merchantId: "Merchant ID",
                    terminalId: "Terminal ID",
                    number: 'Install. No',
                    total: 'Total Installments',
                    paymentDate: "Merchant’s Bank credit date",
                    trxId: "Transaction ID",
                    status: "Status",
                    amount: "Amount"
                }
            }
        },
        reports: {
            titles: {
                transactions: "Transactions count and amount",
                components: "DATA",
                perAcquirer: "per Payment Provider",
                ofCards: "per Card Scheme",
                transactionTypes: "per Transaction Type",
                installmentsTotals: "Transactions with installments",
                redemption: "Transactions with Redemption",
                bankTotals: "Total transactions (live)"
            },
            search: {
                dateTimePick: "Select time period",
                companyPick: "Select company (Tax Id)",
                searchRange: "Search Range",
                reportPick: "Select report",
                allCompanies: "All companies",
                toAllStores: "All stores (MIDs)",
                toSpecificStores: " Selected stores (MIDs)",
                allReports: "Select Report",
                download: "Download Report",
                reportDownloadPanel: "Select and download the reports you want.",
                radioButtons: {
                    xls: "xls",
                    csv: "csv"
                }
            },
            tableTitles: {
                number: "Transactions Count",
                count: "Total Transactions"
            },
            contactless: "Contactless",
            notContactless: "Non Contactless",
            installments: {
                perMonth: " / month",
                perDay: " / day",
                perPeriodCaption: "Transactions with installments",
                noPeriodData: "There are no data available for the specified time period.",
                noAfmChecked: "Select at least on company"
            },
            pieChart: {
                title: "Amount (€)",
                titleBanksColumnName: "Cooperations",
                titleCardsColumnName: "Cards"
            },
            chart: {
                dropdown: {
                    baseValueWeek: "Current week",
                    baseValueMonth: "Previous month"
                }
            },
            settledCount: "AUTHORIZED",
            approvedCount: "TO BE SETTLED",
            redemptionTitle: "Redemption",
            redemptionTitleTable: "Redemption</br>amount",
            monthTitle: "Month & year",
            amountWithCardTitleTable: "Amount</br>with card",
            amountWithCardTitle: "Amount",
            amountTitle: "Amount (€)",
            countTitle: "Transactions Count",
            countTitleTable: "Transaction</br>Count",
            totalSum: "TOTAL AMOUNT",
            scheme: "Card",
            mode: "Transaction type",
            checkboxesHeader: "Companies included in the chart",
            transactionTypes: {
                purchase: "Purchase",
                preAuth: "Preauth",
                refund: "Refund",
                preAuthCom: "Preauth Completion",
                mailPhone: "Mail/Telephone Order",
                transVoid: "Void",
                revAuto: "Auto Reversal",
                asfalistikosGoneas: "Asfalistikos Goneas",
                utilityPayment: "Bill Payment",
                utilityPaymentVf: "Bill Payment - VF",
                utilityPaymentFa: "Bill Payment - NG",
                prepaidBalance: "Prepaid Balance",
                prepaidCredit: "Prepaid Loading",
                prepaidRedemption: "Prepaid Redemption",
                balanceInquiry: "Balance Inquiry",
                restOfTransaction: "Other transaction types"
            },
            download: {
                errors: {
                    midCannotSeeReport: "Report %{report} does not correspond to Mid %{mid}",
                    downloadFailed: "An error occurred, please try again later."
                },
                warnings: {
                    day: "The allowed time period range of the report \"%{report}\" is one day.",
                    days: "The allowed time period range of the report  \"%{report}\" is %{dateRangeValue} days.",
                    month: "The allowed time period range of the report  \"%{report}\" is one month.",
                    months: "The allowed time period range of the report \"%{report}\" is %{dateRangeValue} months.",
                    excelRowLimitReached: "Report \"%{report}\" size exceeded Excel\"s maximum records limit. The report will be downloaded as csv file."
                }
            }
        },
        bankTotals: {
            table: {
                titles: {
                    mid: "MID ",
                    location: "LOCATION: ",
                    allMids: "All MIDs",
                    bank: "Cooperations",
                    purchase: "PURCHASES",
                    refund: "REFUNDS",
                    void: "VOIDS",
                    redemption: "REDEMPTIONS",
                    sum: "TOTALS"
                },
                emptyTableMessageTitle: "There are no transactions for the specified time period."
            },
            searchModal: {
                labels: {
                    timePeriod: "Select date range",
                    midsCheckbox: "All stores (MID)",
                    merchantLabel: "Search by MID",
                    report: "Download Report"
                }
            }
        }
    },
    modals: {
        profile: {
            logoSpecifications: "The image size must be less than 4MB",
            firstName: "Name",
            lastName: "Surname",
            saveButton: "Save",
            emailSaveButton: "Submit Change",
            passwordPlaceholder: "Password",
            newEmail: "New Email",
            confirmNewEmail: "Confirm New Email",
            oldPassword: "Old Password",
            newPassword: "New Password",
            confirmNewPhone: "Password",
            confirmNewPassword: "Confirm New Password",
            newPhone: "New Phone Number",
            confirmPhone: "Confirm Phone Number",
            usernameRequirements: "Username must contain two words (name and surname) with space between them",
            passwordRequirements: "Password must contain at least 12 characters, latin letters (capital and small), numbers and special characters",
            submitImageButton: "Save",
            success: {
                imageUploadSuccessfully: "Successful image upload!",
                nameChangedSuccessfully: "Successful change!",
                emailChangedSuccessfully: "Confirm your new email at the link you received.",
                passwordChangedSuccessfully: "Successful change!",
                phoneChangedSuccessfully: "Successful change!"
            },
            errors: {
                imageNotChanged: "An error occurred while saving the new profile image",
                nameNotChanged: "An error occurred while saving the username",
                emailSameAsConfirm: "The field values do not match",
                phoneSameAsConfirm: "The field values do not match",
                emailAlreadyExists: "This email already exists",
                passwordIsIncorrect: "Password is not correct",
                passwordSameAsConfirm: "The field values do not match",
                passwordNotChanged: "An error occurred while saving new password",
                oldPasswordError: "The old password is not correct",
                commonPasswordError: "This password appears on the most frequently used passwords.",
                midPasswordError: "Password must contain at least 12 characters, latin letters (capital and small), numbers and special characters.",
                samePasswordError: "New password must not equal the old one",
                passwordHistoryError: "Please choose a new password that you haven't used before.",
                tooManyChangePasswordAttempts: 'Too many change password attempts. Please try again later.'
            },
            image: {
                title: "USER PROFILE IMAGE",
                value: "jpeg, png or gif < 4MB",
                delete: "Delete image"
            },
            username: {
                title: "USERNAME",
                change: "USERNAME CHANGE",
                input: {
                    name: "Name",
                    lastname: "Surname"
                }
            },
            email: {
                title: "EMAIL",
                change: "EMAIL CHANGE",
                input: {
                    new: "New email",
                    confirm: "Confirm email",
                    password: "Password"
                }
            },
            phone: {
                title: "MOBILE PHONE",
                change: "MOBILE PHONE CHANGE",
                input: {
                    new: "New Phone",
                    confirm: "Confirm Phone",
                    password: "Password",
                    validPhoneNumber: "Please enter a valid phone number"
                },
                footnote: "* Mobile phone number is required. <b>If you want to inform the cooperations or Cardlink about this change, please send an email to support@cardlink.gr.</b>"
            },
            password: {
                title: "PASSWORD",
                change: "PASSWORD CHANGE",
                input: {
                    old: "Old password",
                    new: "New password",
                    confirm: "Confirm new password"
                }
            },
            closeEditAvatar: "Clear and close form",
            saveEditAvatar: "Save profile image",
            deleteAvatar: "Delete profile image"
        },
        userManagement: {
            title: "User administration",
            caption: "Here you can view the company users and deactivate the access of any user, in case of username/password leakage, moving the \"Activated\" toggle button",
            superUser: "RESPONSIBLE USER",
            legalRepresentative: "LEGAL REPRESENTATIVE",
            simpleUser: "USER",
            userAdded: "User addition",
            lastConnection: "Last login",
            enabled: "Activated",
            disabled: "Deactivated",
            notConfirmed: "Invitation acceptance is pending",
            lockedToggle: "Locked toggle button. Only Cardlink can deactivate this user",
            userToggleOn: "This user is activated. Deactivating his/her user account, you exclude him/her from accessing Cardlink apollo.",
            userToggleOff: "This user account is deactivated. Only activated users are granted access to Cardlink apollo."
        },
        termsAndConditions: {
            title: "Cardlink apollo Terms and Conditions",
            attention: "<b>CAUTION!</b> To continue using Cardlink apollo, you must accept the new terms and conditions.",
            acceptButton: "I Accept"
        },
        support: {
            category: {
                title: "Category",
                dropdown: {
                    request: "Request",
                    failure: "Malfunction",
                    complain: "Complaint",
                    selection: "Select a category"
                }
            },
            company: {
                title: "Company",
                dropdown: {
                    selection: "Select a company",
                    allCompanies: "All companies"
                }
            },
            mid: {
                title: "Store (MID)",
                dropdown: {
                    selection: "Select MID",
                    allMids: "All MIDS"
                }
            },
            tid: {
                title: "POS (TID)",
                dropdown: {
                    selection: "Select TID",
                    allTids: "All TIDS"
                }
            },
            textArea: {
                title: "Message",
                placeholder: "Write your message",
                placeholderB: "Select a category to write your message",
                contentError: "Message content is invalid. Please provide a new message."
            },
            fileUpload: {
                dragAndDrop: "Attach your files <br/>(jpeg, png, gif or pdf < 4MB)",
                attachedFiles: "Attached files",
                title: "Attach file",
                error: "This file cannot be attached. Please check the file format specifications."
            },
            buttonSubmit: "Submit",
            emailFetching: "Sending email...",
            business: {
                message: "Need help?",
                button: "Contact us"
            },
            response: {
                positive: "Your message is sent successfully!",
                negative: "An error occurred, please try again later.",
                close: "Close"
            },
            email: {
                labels: {
                    timeStamp: "Sent: ",
                    cause: "Reason: ",
                    taxId: "Tax ID(s): ",
                    mid: "MID: ",
                    tid: "TID: ",
                    email: "Email: ",
                    fullName: "Full name: ",
                    mobile: "Mobile: ",
                    corpTitle: "Corporate title(s): ",
                    message: "Message: "
                },
                footer: {
                    subject: "Cardlink: Give us your feedback!",
                    portalLabel: "Sent via Cardlink apollo - Give us your feedback!",
                },
                aside: {
                    subject: "Cardlink: Do you need help?",
                    portalLabel: "Sent via Cardlink apollo - Do you need help? Support",
                },
                business: {
                    subject: "Cardlink: Do you need help?",
                    portalLabel: "Sent via Cardlink apollo - Do you need help?"
                },
            }
        }
    },
    common: {
        forms: {
            help: "Contact us at support@cardlink.gr or at 2106303000",
            needHelp: "Need help?"
        },
        fastSearch: {
            label: "Quick Search",
            placeholder: "MID, TID, ALIAS"
        },
        answers: {
            yes: "YES",
            no: "NO"
        },
        noPosInAccount: "You don't have any POS linked to your user account, please contact Cardlink."
    },
    navMenu: {
        links: {
            overview: "DASHBOARD",
            business: "COMPANY",
            reports: "REPORTS",
            transactions: "TRANSACTIONS",
            bankTotals: "COOPERATION TOTALS"
        },
        bubble: {
            title: "Explore apollo quickly",
            text: "TUTORIAL functionality is available on each page. You can activate it every time you need info about Cardlink apollo functionality."
        }
    },
    orderStatus: {
        loading: 'Checking application status ...',
        none: {
            button: "Pending payment",
            modal: {
                title: "Pending payment",
                text: "Your <b>Cardlink one</b> application has been submitted. Once it is paid it will be processed.\n" +
                    "Please attach <a href=%{mbsLink}>here</a> any pending documents regarding your application.",
                bottomBtn: "Close"
            }
        },
        pending: {
            button: "Pending",
            modal: {
                title: "In progress",
                text: "Your <b>Cardlink one</b> application has been successfully submitted and you will be notified shortly about its status by e-mail.\n" +
                    "Please attach <a href=%{mbsLink}>here</a> any pending documents regarding your application.",
                bottomBtn: "Close"
            }
        },
        partiallyActivated: {
            button: "Partially activated",
            modal: {
                title: "In use under limit",
                text: "Your Cardlink one application is being processed.\n" +
                    "We will soon e-mail you regarding its approval. Until then, you have a €500 total transaction limit.\n" +
                    "Please attach <a href=%{mbsLink}>here</a> any pending documents regarding your application.",
                bottomBtn: "Close"
            }
        },
        blocked: {
            button: "Blocked",
            modal: {
                title: "Over limit",
                text: "You have reached the €500 in total transactions, which is the limit until your application at <b>Cardlink one</b> is fully processed. We will keep you updated about the status of your application.\n" +
                    "Please attach <a href=%{mbsLink}>here</a> any pending documents regarding your application.\n" +
                    "For any further information, you can call us at 2106306050 or email us at <a href=\"mailto:one@cardlink.gr\">one@cardlink.gr</a>.",
                bottomBtn: "Close"
            }
        },
        activated: {
            button: "Activated",
            modal: {
                title: "Application activated",
                text: "Your application has been approved. Thank you for choosing <b>Cardlink one</b>.",
                bottomBtn: "Close"
            }
        },
        declined: {
            button: "Declined",
            modal: {
                title: "Application declined",
                text: "We would like to inform you that your application has not been approved.\n" +
                    "For any information you need, you can call us at 2106306050 or email us at <a href=\"mailto:one@cardlink.gr\">one@cardlink.gr</a>.",
                bottomBtn: "Close"
            }
        },
        cancelled: {
            button: "Cancelled",
            modal: {
                title: "Application cancelled",
                text: "Your application has been cancelled. <b>Cardlink one</b> is available if you want to start a new application.",
                bottomBtn: "Start application"
            }
        }
    },
    modalList: {
        options: {
            profile: "PROFILE",
            notifications: "NOTIFICATIONS",
            userManagement: "USER ADMINISTRATION",
            smartSettings: "SMART SETTINGS"
        }
    },
    externalList: {
        links: {
            support: "SUPPORT",
            help: "TUTORIAL",
            terms: "TERMS & CONDITIONS",
            privacyPolicy: "PRIVACY POLICY",
            cookies: "COOKIES",
            cookiePreferences: "PREFERENCES",
            cookiesPolicy: "POLICY"
        }
    },
    header: {
        cookiesBanner: {
            text: "By using this website, you accept cookies",
            more: "More",
            accept: "I Accept"
        }
    },
    footer: {
        support: "Give us your feedback! Your opinion matters to us!",
        modal: {
            header: "Give us your feedback!"
        }
    },
    loader: {
        dashboard: "Downloading transactions statistic data...",
        generic: "Downloading data",
        trxEmail: "Sending email",
        trxSubMessage: "Check your inbox in a while",
        receiptPdf: "Generating e-Receipt file"
    },
    errorBoundary: {
        title1: ":( ",
        title2: "An error occurred"
    },
    hotSpots: {
        companiesDropdown: "Select the company about which you wish to view info.",
        storesMultiselect: "Select one or more stores to view info.",
        reportsTransactions: "You filter the chart results, by changing the dates or moving the bars below.",
        quickSearch: "Fill in one of the company data to view its details.",
        reportsBanks: "Here you can: <ul><li> Download the chart as an image </li><li> Save its data as CSV, XLSX or JSON </li><li> Add text or shapes on it </li><li> Print the chart</li></ul>",
        downloadReports: "Select time period, company or store and download various reports.",
        cardScheme: "Here you can switch the type of data results (transactions type and card type).",
        cardsDates: "Select a time period to filter the results.",
        transactionTypes: "You can view more transaction categories, if you unselect the category Purchase.",
        exportChartHints: "Here you can: <ul><li> Download the chart as an image </li><li> Save its data as CSV, XLSX or JSON </li><li> Add text or shapes on it </li><li> Print the chart</li></ul>",
        downloadBusinessExcel: "Download the file with your company data (MID & TID).",
        transactionDetailsInfo: "Select one transaction to view more details and print them.",
        transactionSettlingInfo: "The transactions of every day are included every night at 00:00 in the settlement file.",
        settledTransactions: "Transactions which are included in the settlement file(s) towards your Payment Provider(s).",
        pendingTransactions: "Transactions which are not included in the settlement file(s) towards your Payment Provider(s).",
        threeDSecureHeader: "Check if the transactions are 3D Secure authenticated.",
        eCommerceBusiness: "Here you can find information about your e-shop.",
        transactionExcelButton: "The transactions file you will receive at your email will contain all the details of your transactions.",
        responseCodeHeader: "<b>x</b> appears when a transaction is not approved.",
        isSettledTransactionHeader: "<ul><li>Orange color appears next to the transactions which are not included in the settlement file(s) towards your Payment Provider(s).</li> <li>Purple color appears next to the transactions which are included in the settlement file(s) towards your Cooperation(s).</li></ul>"
    },
    tooltips: {
        hover: {
            timeoutSession: "Countdown for automatic logout from Cardlink apollo",
            seeTRXbtn: "View in detail the transactions of the specific time period",
            tooltip: "Average transactions value for the specified time period and min/max transaction.",
            supportFormTooltip: "Contact Form with Cardlink",
            logOut: "Log out",
            profileAvatar: "Your profile",
            timeDropdownKPI: "Select time period",
            ssoLink: "Find everything you need to know about you POS at Cardlink Support",
            trxDownloadBTN: "Get the link at your inbox and download the transactions (up to 100.000 transactions per link)",
            trxLeftPaperclip: "Transaction included in a settlement file sent to the Payment Provider.",
            trxLeftClock: "Approved transaction not included in a batch yet or rejected transaction.",
            trxLeftInBatch: "Transaction included in a batch.",
            trxLeftCheck: "Successful credit to bank account.",
            trxLeftFail: "Unsuccessful credit to bank account.",
            trxStatusOk: "Approved",
            trxStatusRejected: "Not approved",
            trxStatusCardlinkOneRejected: "Not approved - Company’s limit reached",
            reportDownloadPanel: "Select and download the reports .",
            timeDropdown01: "Select time period",
            timeDropdown02: "Select time period",
            timeDropdown03: "Select time period",
            timeDropdown04: "Select time period",
            timeDropdown05: "Select time period",
            addAvatar: "Add a profile picture",
            editName: "Data editing",
            editEmail: "Data editing",
            editMobile: "Data editing",
            editPassword: "Data editing",
            lockedToggle: "Locked toggle button. Only Cardlink can deactivate this user",
            userToggleOn: "This user is activated. Deactivating his/her user account, you exclude him/her from accessing Cardlink apollo.",
            userToggleOff: "This user account is deactivated. Only activated users are granted access to Cardlink apollo."
        },
        click: {
            businessTidAlias: "Set an alternative name (Alias) for your POS to find it easily at the Company page and at the Quick Search field",
            companyDlMultipleMid: "Add MID(s) to download data only for these stores.",
            companyDlMultipleTid: "Add TID(s) to download data only for these POS.",
            mainMultipleMID: "Add MID(s) to limit your results only to these stores (MID).",
            companyTKInfo: "The stores (MIDs) are grouped by regions based on Post Code. If the correlation is not correct, you can edit the Post Code at the Company (MID) page",
            tooltipMid: "Set an alternative name (Alias) for your store (MID) to find it easily at the Company page and at the Quick Search field",
            reportDownloadPanelMultipleMID: "Add MID(s) to download reports only for specific stores",
            reportsRightColumnTitleTltp: "Charts contain data till the previous day, except for Total Transactions (live) which shows data with 1-minute delay.",
            quickFind: "Fill in the MID, TID or the Alternative Name (Alias) to find quickly their data",
            trxSearchMultipleMID: "Add MID(s) to make your search more specific",
            downloadExcelTooltip: "You can receive up to 100.000 transactions every time",
            refund: "Refund transaction can't be completed if: <br/> 1. 1 year have passed after the initial transaction settlement <br/> 2. Transaction was performed without card presence. <br/>",
            tooltipTid: "Set an alternative name (Alias) for your POS to find it easily at the Company page and at the Quick Search field",
            tooltipTrancactionsChart: "Charts include data till the previous day.",
            overviewKpiTitleTltp: "Charts contain data till the previous day.",
            duplicateTransactionsTitleTltp: "Show transactions with the same: TID, date, amount, etc."
        }
    }
}

